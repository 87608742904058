@import "~antd/dist/reset.css";
@import "fonts/fonts.css";

* {
    font-family: Geometria, Arial, sans-serif;
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    width: 100%;
    height: 100vh;
    /*overflow: hidden;*/
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root{
    display: flex;
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
